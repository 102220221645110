import React from 'react'
import classNames from 'classnames'
import { Campaign } from '@/services/api/campaign'
import CampaignCard from '../CampaignCard'
import Type from '../Type'

interface Props {
  campaigns: Campaign[]
  className?: string
  preTitle?: string
  subtitle?: string
  text?: string
  title: string
}

export const CampaignSection: React.FC<Props> = ({
  campaigns,
  className,
  preTitle,
  subtitle,
  text,
  title,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'mb-12 w-full max-w-[1168px] px-4 md:mb-14',
        className
      )}
      {...rest}
    >
      {preTitle && (
        <Type
          as="p"
          variant={['eyebrow-md', 'md:eyebrow-lg']}
          className="mb-1 text-core-gray-600"
        >
          {preTitle}
        </Type>
      )}
      <Type
        as="h2"
        variant={['heading-xs', 'md:heading-xl']}
        className="font-bold md:mr-2 md:inline-block"
      >
        {title}
      </Type>
      {subtitle && (
        <Type
          as="p"
          variant={['title-xs', 'md:title-lg']}
          className="text-core-gray-700 md:inline-block"
        >
          {subtitle}
        </Type>
      )}

      {text && (
        <Type
          as="p"
          variant={['caption-lg', 'md:paragraph-lg']}
          className="mt-3 mb-8"
        >
          {text}
        </Type>
      )}

      <div
        className={classNames('mt-4 grid gap-4', {
          'sm:grid-cols-2': campaigns.length >= 2,
          'lg:grid-cols-3': campaigns.length >= 3,
        })}
      >
        {campaigns.map((campaign) => (
          <CampaignCard
            key={campaign.slug}
            campaign={campaign}
            layout={campaigns.length === 1 ? 'horizontal' : 'vertical'}
          />
        ))}
      </div>
    </div>
  )
}

export default CampaignSection
