import React from 'react'
import Link from 'next/link'
import { ChevronRight } from '@/components/svg'
import Button from '../Button'

interface Props {
  cta: string
  ctaVariant?: 'primary' | 'link'
  url: string
}

const Footer: React.FC<Props> = ({
  children,
  cta,
  ctaVariant = 'primary',
  url,
}) => {
  return (
    <div className="mt-auto flex items-end justify-between gap-1 pt-4">
      <div>{children}</div>
      <Link href={url} className="flex-[0_0]">
        <Button
          variant={ctaVariant}
          className="flex items-center whitespace-nowrap"
          color={ctaVariant === 'primary' ? 'black' : 'oxide'}
          size="sm"
          tabIndex={-1}
        >
          {cta}
          <ChevronRight className="ml-0.5 h-5 w-5" />
        </Button>
      </Link>
    </div>
  )
}

export default Footer
